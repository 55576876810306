@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.filter-input-box {
  border-width: 1px;
  border-color: "#e2e8f0";
  background-color: "#FCFCFC";
}

.form-input {
  border-width: 1px;
  border-color: "#e2e8f0";
  background-color: "#FCFCFC";
  height: 45px;
}

.form-select {
  border-width: 1px;
  border-color: "#e2e8f0";
  background-color: "#FCFCFC";
  height: 45px;
}

/* Custom styles for google places autocomplete widget custom styles */
.pac-container {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.pac-icon {
  color: #f06116;
}
.pac-item {
  padding: 0.5em 0.5em;
}
.pac-item-query {
  color: #222222;
}
.pac-item:hover {
  background: #6666;
}
.pac-matched {
  font-weight: bold;
}
